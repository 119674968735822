export default [
  {
    title: 'menu.dashboard',
    route: 'admin-dashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'menu.personnel',
    route: 'personnel-list',
    icon: "UsersIcon",
    permissions: ['user-create', 'user-list']
  },
  {
    title: 'menu.person',
    route: 'person-list',
    icon: 'UserIcon',
    permissions: ['person-create', 'person-list']
  },
  {
    title: "menu.identifications",
    route: 'identification-list',
    icon: "SearchIcon",
    permissions: ['identification-create', 'identification-list']
  },
  {
    title: "menu.verifications",
    route: 'verification-list',
    icon: "RepeatIcon",
    permissions: ['verification-create', 'verification-list']
  }
]
