<template>
  <vue-lightbox
    :media="media"
    :showLightBox="modalVisible"
    v-if="media && media.length > 0"
    @onClosed="() => (visible = false)"
  />
</template>

<script>
import { default as VueLightbox } from "vue-image-lightbox";

import "swiper/css/swiper.css";
import { mapGetters } from "vuex";
export default {
  components: {
    VueLightbox,
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    ...mapGetters(["activeImages"]),
    modalVisible() {
      return this.activeImages && this.activeImages.length > 0;
    },
    media() {
      return this.activeImages.map((image) => ({
        thumb: image.src,
        src: image.original ?? image.src,
      }));
    },
  },
  watch: {
    modalVisible() {
      this.visible = this.modalVisible;
    },
    visible() {
      if (!this.visible) {
        this.$store.commit("selectGroup", { groupId: null });
      }
    },
  },
};
</script>

<style>
@import "~vue-image-lightbox/dist/vue-image-lightbox.min.css";
</style>