<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
    v-if="user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ user.name }}
        </p>
        <span class="user-status"></span>
      </div>
      <b-avatar
        size="40"
        :src="user.avatar"
        :text="user.name | avatarText"
        variant="light-primary"
        class="badge-minimal"
      >
        <feather-icon v-if="!user.name" icon="UserIcon" size="22" />
      </b-avatar>
    </template>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      :to="{ name: 'self-profile' }"
    >
      <feather-icon size="16" icon="UserIcon" class="mr-50" />
      <span>{{ $t("dashboard.my-profile") }}</span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      :to="{ name: 'organization-list' }"
      v-if="
        user.guard_name == 'organization' &&
        user.permissions.includes('organization-show')
      "
    >
      <feather-icon size="16" icon="ServerIcon" class="mr-50" />
      <span>{{ $t("menu.organization") }}</span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      :to="{ name: 'billing-list' }"
      v-if="
        user.guard_name === 'organization' &&
        user.permissions.includes('billing-list')
      "
    >
      <feather-icon size="16" icon="CreditCardIcon" class="mr-50" />
      <span>{{ $t("menu.billing") }}</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>{{ $t("common.actions.logout") }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      avatarText,
      user: null
    };
  },
  methods: {
    logout() {
      this.$auth.logout({});
    },
  },
  created() {
    this.$auth.load().then(() => {
      this.user = this.$auth.user()
    })
  }
};
</script>
