<template>
  <div
    class="app-content content"
    :class="[
      { 'show-overlay': $store.state.app.shallShowOverlay },
      $route.meta.contentClass,
    ]"
  >
    <template v-if="guardName === 'organization'">
      <b-alert
        show
        v-if="pastDue && $auth.user().permissions.includes('billing-list')"
        class="text-center"
        style="margin-top: -0.75rem"
        variant="warning"
      >
        <div class="alert-body">
          <feather-icon icon="ClockIcon" class="mr-1" />
          <span>{{ $t("subscription.sub-past-due") }}</span>
          <b-button
            :to="{ name: 'billing-list' }"
            variant="outline-primary"
            size="sm"
            class="ml-1"
          >
            <strong>
              {{ $t("menu.billing") }}
            </strong>
          </b-button>
        </div>
      </b-alert>

      <b-alert
        show
        v-if="
          paymentPending && $auth.user().permissions.includes('billing-list')
        "
        class="text-center"
        style="margin-top: -0.75rem"
        variant="warning"
      >
        <div class="alert-body">
          <feather-icon icon="ClockIcon" class="mr-1" />
          <span>{{ $t("subscription.has_payment_pending") }}</span>
          <b-button
            :to="{ name: 'billing-list' }"
            variant="outline-primary"
            size="sm"
            class="ml-1"
          >
            <strong>
              {{ $t("menu.billing") }}
            </strong>
          </b-button>
        </div>
      </b-alert>

      <b-alert
        show
        class="text-center"
        style="margin-top: -0.75rem"
        variant="warning"
        v-else-if="endsAt"
      >
        <div class="alert-body">
          <feather-icon icon="ClockIcon" class="mr-1" />
          <span v-html="endsAt"></span>
          <b-button
            v-if="$auth.user().permissions.includes('billing-list')"
            :to="{ name: 'pricing' }"
            variant="outline-primary"
            size="sm"
            class="ml-1"
          >
            <strong>
              {{ $t("subscription.trial-select-plan") }}
            </strong>
          </b-button>
        </div>
      </b-alert>
      <b-alert
        show
        v-else-if="noSub"
        class="text-center"
        style="margin-top: -0.75rem"
        variant="warning"
      >
        <div class="alert-body">
          <feather-icon icon="ClockIcon" class="mr-1" />
          <span>{{ $t("subscription.no-sub-cta") }}</span>
          <b-button
            v-if="$auth.user().permissions.includes('billing-list')"
            :to="{ name: 'pricing' }"
            variant="outline-primary"
            size="sm"
            class="ml-1"
          >
            <strong>
              {{ $t("subscription.no-sub-cta-button") }}
            </strong>
          </b-button>
        </div>
      </b-alert>
    </template>

    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      class="content-wrapper"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
    >
      <slot name="breadcrumb">
        <app-breadcrumb />
      </slot>
      <div class="content-body">
        <transition :name="routerTransition" mode="out-in">
          <slot />
        </transition>
      </div>
    </div>

    <lightbox />
  </div>
</template>

<script>
import AppBreadcrumb from "@core/layouts/components/AppBreadcrumb.vue";
import useAppConfig from "@core/app-config/useAppConfig";

import Lightbox from "@/components/Lightbox.vue";
export default {
  components: {
    AppBreadcrumb,
    Lightbox,
  },
  computed: {
    endsAt() {
      let endsAt = this.$auth?.user()?.organization?.trial_ends_at;
      if (endsAt) {
        return this.$t("subscription.trial-cta", {
          endsAt: this.$d(new Date(endsAt * 1000), "long", "en"),
        });
      }
    },
    pastDue() {
      return this.$auth?.user()?.organization?.has_past_due_subscription;
    },
    noSub() {
      const noSub = !this.$auth?.user()?.organization?.has_active_subscription;
      return noSub;
    },
    paymentPending() {
      const paymentPending =
        this.$auth?.user()?.organization?.has_pending_payment;
      return paymentPending;
    },
    guardName() {
      return this.$auth?.user()?.guard_name
    }
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig();

    return {
      routerTransition,
      contentWidth,
    };
  },
};
</script>

<style>
</style>
