<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import LayoutVertical from "@core/layouts/layout-vertical/LayoutVertical.vue";
import LayoutHorizontal from "@core/layouts/layout-horizontal/LayoutHorizontal.vue";
import { $themeConfig } from "@themeConfig";
export default {
  components: { LayoutVertical, LayoutHorizontal },
  computed: {
    layout() {
      if ($themeConfig.layout.type == "horizontal") {
        return LayoutHorizontal;
      }
      return LayoutVertical;
    },
  },
};
</script>

<style>
</style>