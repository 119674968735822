<template>
  <div class="navbar-header d-xl-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link class="navbar-brand" to="/">
          <span class="brand-logo mr-1">
            <b-img
              v-if="skin === 'dark'"
              style="width: 1.5rem"
              :src="require('@/assets/images/logo/frio-logo-dark.png')"
              alt="logo"
            />
            <b-img
              v-else
              style="width: 1.5rem"
              :src="require('@/assets/images/logo/frio-logo-light.png')"
              alt="logo"
            />
          </span>
          <h2 class="brand-text mb-0" :class="skin == 'dark' && 'text-white'">
            {{ appName }}
          </h2>
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { BLink, BImg } from "bootstrap-vue";
import { $themeConfig } from "@themeConfig";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {
    BLink,
    BImg,
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app;
    const { skin } = useAppConfig();
    return {
      appName,
      appLogoImage,
      skin,
    };
  },
};
</script>

<style>
</style>
